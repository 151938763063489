import React from "react";
import Footer from "../ui/Footer";
import Header from "../ui/Header";
import ServicesSections from "./ServicesSections";

import line from "../../assets/img/utilities/red-line.png";

const ServicesScreen = () => {
  return (
    <>
      <Header
        banner="banner-services"
        title="servicios"
        line={line}
        quote="Estrategias para tu negocio"
      />

      <ServicesSections />

      <Footer />
    </>
  );
};

export default ServicesScreen;
