import React, { useState } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

const Form = () => {
  const [values, setValues] = useState({
    clientName: "",
    clientEmail: "",
    clientMsg: "",
  });

  // const [showAlert, setShowAlert] = useState(false)
  const { clientName, clientEmail, clientMsg } = values;

  const reset = () => {
    setValues({
      clientName: "",
      clientEmail: "",
      clientMsg: "",
    });
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "default_service",
        "template_aksmuou",
        values,
        "user_Jb3cEB3OPHPP8B4kFFn8B"
      )
      .then(
        (response) => {
          // console.log("SUCCESS!", response.status, response.text);
          setValues(values);
          Swal.fire("Mensaje enviado", "", "success");
          reset();
        },
        (err) => {
          // console.log("FAILED...", err);
          Swal.fire(
            "Error",
            "No se pudo enviar su mensaje, inténtelo más tarde",
            "error"
          );
        }
      );
  };

  return (
    <div className="contact-container">
      <div className="contact-form">
        <div className="login-form">
          <h2 className="heading-2">Contacto</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Nombre</label>
              <input
                type="text"
                placeholder="Su nombre"
                required
                name="clientName"
                value={clientName}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Correo</label>
              <input
                type="email"
                placeholder="correo@gmail.com"
                required
                name="clientEmail"
                value={clientEmail}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <textarea
                placeholder="Su mensaje..."
                name="clientMsg"
                required
                value={clientMsg}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <input type="submit" value="Enviar" className="btn-submit" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
