import React from "react";
import { NavLink } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

import lineBlue from "../../assets/img/utilities/blue-line.png";

const ServicesSections = () => {
  return (
    <main className="services">
      <div className="msg-red">
        <p className="msg">Nuestra Experiencia</p>
        <hr className="msg-line" />
      </div>

      <ScrollAnimation duration={2} animateIn="animate__fadeInRightBig">
        <img src={lineBlue} alt="Línea" className="services-line-blue" />
      </ScrollAnimation>

      <section className="container">
        <div className="intro-services">
          <p>
            Todos los servicios que presta el CorporativoASM Fiscalistas tienen
            por FINALIDAD que las empresas y empresarios cumplan con su
            obligación tributaria de forma optima, dentro de un contexto legal,
            fiscal y financiero, considerando su repercusión en el mundo de los
            negocios, que les brinde a su vez COMPETITIVIDAD, LIQUIDEZ Y
            SEGURIDAD JURÍDICA, cuidando los detalles como parte esencial de
            cada caso.
          </p>
        </div>
      </section>

      <section className="container services-section">
        <div className="card">
          <div className="card__side card__side--front card__side--front-1">
            <h2>
              Gestión <br /> Tributaria
            </h2>
          </div>

          <div className="card__side card__side--back">
            <h3>Gestión Tributaria</h3>
            <p>
              La Gestión Tributaria consiste en aplicar aquellas alternativas
              permitidas por las leyes fiscales, que redunden en beneficios
              económicos y certeza jurídica en el contexto del patrimonio
              empresarial, que generen liquidez, al proporcionar elementos de
              cómo hacer mejor las cosas, requiriéndose para esto de una visión
              diferente en el ámbito de los negocios por parte de los
              empresarios.
            </p>
            <div className="btn">
              <NavLink exact to="/gestion-tributaria" className="btn__link">
                Leer Más
              </NavLink>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card__side card__side--front card__side--front-2">
            <h2>
              Penal fiscal <br /> estratégico
            </h2>
          </div>

          <div className="card__side card__side--back">
            <h3>Penal fiscal estratégico</h3>
            <p>
              En el escenario fiscal nacional y acorde con nuestro sistema
              tributario, EXISTEN DOS CLASES DE INFRACCIONES que son sancionadas
              por el Código Fiscal de la Federación.
            </p>
            <div className="btn">
              <NavLink
                exact
                to="/penal-fiscal-estrategico"
                className="btn__link"
              >
                Leer Más
              </NavLink>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card__side card__side--front card__side--front-3">
            <h2>
              defensa fiscal <br /> creativa
            </h2>
          </div>

          <div className="card__side card__side--back">
            <h3>defensa fiscal creativa</h3>
            <p>
              Conscientes de la necesidad que tienen nuestros clientes de contar
              con asesoría legal y corporativa, no podrían estar sólidamente
              respaldadas sin la defensa de sus derechos.
            </p>
            <p>
              Por lo que, practicamos de manera proactiva e innovadora los
              medios impugnativos que ofrecen las leyes de la materia, buscando
              las mejores Alternativas de defensa para una resolución favorable.
            </p>
            <div className="btn">
              <NavLink
                exact
                to="/defensa-fiscal-creativa"
                className="btn__link"
              >
                Leer Más
              </NavLink>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card__side card__side--front card__side--front-4">
            <h2>Capacitacion</h2>
          </div>

          <div className="card__side card__side--back">
            <h3>capacitación</h3>
            <p>
              A lo largo de los años y producto de la experiencia como
              instructores en diversos foros del país degustando y compartiendo
              con empresarios, contadores, abogados, entre otros, el
              CorporativoASM Fiscalistas da un valor agregado, por medio de la
              transmisión de conocimientos, teniendo un puente que fortalezca el
              crecimiento y progreso de las empresas.
            </p>
            <div className="btn">
              <NavLink exact to="/capacitacion" className="btn__link">
                Leer Más
              </NavLink>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card__side card__side--front card__side--front-5">
            <h2>
              Corporativo <br /> patrimonial
            </h2>
          </div>

          <div className="card__side card__side--back">
            <h3>corporativo patrimonial</h3>
            <p>
              Una correcta PLATAFORMA LEGAL genera CERTEZA PATRIMONIAL ADEMÁS DE
              LOS BENEFICIOS FISCALES implícitos, siendo importante analizar que
              figuras jurídicas posee el contribuyente o ente económico.
            </p>
            <div className="btn">
              <NavLink
                exact
                to="/corporativo-patrimonial"
                className="btn__link"
              >
                Leer Más
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ServicesSections;
