import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import logo from "../../assets/img/logotipo.jpg";
import Navigation from "./Navigation";

const Navbar = () => {
  const [pos, setPos] = useState(true);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      let scrolled = document.scrollingElement.scrollTop;
      let windowHeight = window.innerHeight;
      if (scrolled > windowHeight) {
        setPos(false);
      } else {
        setPos(true);
      }
    });
  }, []);

  return (
    // <nav
    //   className="navbar"
    //   style={{
    //     position: pos === false && "fixed",
    //     zIndex: pos === false && 2,
    //     backgroundColor: pos === false && "#e30513",
    //   }}
    // >
    <>
      {pos === true ? (
        <nav className="navbar">
          <Link className="navbar__logo" to="/">
            <img src={logo} alt="Logo" />
          </Link>

          <div className="navbar__elements">
            <div className="navbar__elements-info">
              {/* <span>Para mayor info </span>
              <a href="tel:5556157909">
                <i className="fas fa-phone-alt nav-icon"></i>
              </a>
              <span>55-56-15-79-09</span> */}
            </div>

            <div className="navbar__elements-options">
              <NavLink exact to="/">
                Quiénes somos |
              </NavLink>
              <NavLink exact to="/servicios">
                Servicios |
              </NavLink>
              <NavLink exact to="/publicaciones">
                Publicaciones |
              </NavLink>
              <NavLink exact to="/articulos">
                Articulos |
              </NavLink>
              <NavLink exact to="/contacto">
                Contacto |
              </NavLink>
            </div>
          </div>
        </nav>
      ) : (
        <Navigation />
      )}
    </>
    // </nav>
  );
};

export default Navbar;
