import React from "react";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import { penal } from "../utils/services";
import { ServicioPenal } from "./ContentServices";

const PenalFiscal = () => {
  const { intro, contenido, frase } = penal;

  return (
    <>
      <Header banner="banner-penal" title="penal fiscal estrategico" />

      <ServicioPenal intro={intro} contenido={contenido} frase={frase} />

      <Footer />
    </>
  );
};

export default PenalFiscal;
