import React from "react";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import { ServicioGestion } from "./ContentServices";
import { gestion } from "../utils/services";

const GestionTributaria = () => {
  const { intro, contenido, frase } = gestion;

  return (
    <>
      <Header banner="banner-gestion-tributaria" title="GESTIÓN TRIBUTARIA" />

      <ServicioGestion intro={intro} contenido={contenido} frase={frase} />

      <Footer />
    </>
  );
};

export default GestionTributaria;
