import React from "react";
import Header from "../ui/Header";
import HomeSections from "./HomeSections";
import Footer from "../ui/Footer";

import line from "../../assets/img/utilities/red-line.png";

const HomeScreen = () => {
  return (
    <>
      <Header
        banner="banner-home"
        title="¿Quiénes Somos?"
        line={line}
        quote="Expertos en gestión empresarial"
      />

      <HomeSections />

      <Footer />
    </>
  );
};

export default HomeScreen;
