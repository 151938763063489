import React from "react";

import logo from "../../assets/img/logotipo-bn.png";
import { ReactComponent as PhoneIcon } from "../../assets/icons/telefono.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/email.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/ubicacion.svg";
import { ReactComponent as FbIcon } from "../../assets/icons/facebook.svg";
import { ReactComponent as TwIcon } from "../../assets/icons/twitter.svg";

const Footer = () => {
  return (
      <footer>
          <div className='footer-info'>
              <div className='footer-logo'>
                  <img src={logo} alt='Logo' />
              </div>

              <nav className='footer-contacto'>
                  {/* <div className="footer-contacto__icon-box">
            <a href="tel:5556157909">
              <PhoneIcon className="footer-contacto__icon" />
            </a>
            <span className="footer-contacto__content">55-56-15-79-09</span>
          </div> */}

                  <div className='footer-contacto__icon-box'>
                      <a
                          href='mailto:contacto@corporativoasm.com.mx'
                          target='_blank'
                          rel='noreferrer noopener'
                      >
                          <EmailIcon
                              style={{ color: 'black' }}
                              className='footer-contacto__icon'
                          />
                      </a>
                      <span className='footer-contacto__content'>
                          contacto@corporativoasm.com.mx
                      </span>
                  </div>

                  <div className='footer-contacto__icon-box'>
                      {/* <a
              href="https://www.google.com.mx/maps/place/Av.+Divisi%C3%B3n+del+Nte.+2723,+San+Lucas,+Coyoac%C3%A1n,+04030+Ciudad+de+M%C3%A9xico,+CDMX/@19.3501796,-99.1534037,17z/data=!3m1!4b1!4m5!3m4!1s0x85d1ffce007ae477:0xdeeb282dfc2333ab!8m2!3d19.3501796!4d-99.151215"
              target="_blank"
              rel="noreferrer noopener"
            >
              <LocationIcon className="footer-contacto__icon" />
            </a> */}
                      <span>
                          <LocationIcon className='footer-contacto__icon' />
                      </span>
                      <span className='footer-contacto__content'>
                          {/* Av.División del Norte No.2723 Desp.402-B <br />{' '}
                          Col.Barrio San Lucas Del. Coyoacán, <br />
                          Entre las calles Priv. Irlanda e Hidalgo <br />
                          C.P. 04030 México, Ciudad de México */}
                          CDMX, Coyoacán
                      </span>
                  </div>
              </nav>

              <nav className='footer-redes'>
                  <div className='footer-redes__icon-box'>
                      <a
                          href='https://www.facebook.com/www.corporativoasm.com.mx/'
                          target='_blank'
                          rel='noreferrer noopener'
                      >
                          <FbIcon className='footer-redes__icon' />
                      </a>
                      <span className='footer-redes__content'>
                          Corporativo Asm Fiscalistas
                      </span>
                  </div>

                  <div className='footer-redes__icon-box'>
                      <a
                          href='https://twitter.com/CorporativoASM'
                          target='_blank'
                          rel='noreferrer noopener'
                      >
                          <TwIcon className='footer-redes__icon' />
                      </a>
                      <span className='footer-redes__content'>
                          @CorporativoASM
                      </span>
                  </div>
              </nav>
          </div>

          <div className='footer-copy'>
              <p>
                  Imagen Corporativa:{' '}
                  <a
                      href='https://arkacolectivo9.wixsite.com/arkacolectivo'
                      target='_blank'
                      rel='noreferrer noopener'
                  >
                      ARKA COLECTIVO
                  </a>
              </p>
              <p>
                  Todos los derechos reservados ASM Publicaciones 2020&copy;.
                  Powered by Cristian Sánchez
              </p>
          </div>
      </footer>
  );
};

export default Footer;
