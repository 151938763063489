import React from "react";
import { NavLink } from "react-router-dom";

import art1 from '../../assets/docs/pandora-papers.pdf';

import rf from "../../assets/img/articles/reforma-fiscal-2020.jpg";
import outsourcing from "../../assets/img/articles/outsourcing.jpg";
import facturas from "../../assets/img/articles/compra-venta-facturas.jpg";
import gasolina from "../../assets/img/articles/impuestos-gasolina.jpg";

const Articles = () => {
  return (
    <main className="articles">
      <div className="article">
        <div className="article__shadow ">
          <img src={rf} alt="Reforma Fiscla 2020" />
          <div className="article-info">
            <h2>PANDORA PAPERS</h2>
            <hr />
            <p>
              Los Pandora Papers es derivado de una filtración de 12 millones de
              documentos sobre las fortunas de diversas personalidades a nivel
              mundial, mucho mayor a lo visto en los Panamá Papers (2016) y
              Paradise Papers (2017).
            </p>
            <div className="btn-article">
              <NavLink
                to={art1}
                target="_blank"
                rel="noreferrer noopener"
                className="btn-article__link"
              >
                Leer más
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="article">
        <div className="article__shadow ">
          <img src={outsourcing} alt="Outsourcing Fiscal" />
          <div className="article-info">
            <h2>Abatir el Outsourcing Fiscal</h2>
            <hr />
            <p>
              En muchos casos se ha generado un uso arbitrario del outsourcing,
              en donde la empresa contratista abusa del derecho laboral, social
              y fiscal
            </p>
            <div className="btn-article">
              <NavLink
                exact
                to="/"
                target="_blank"
                rel="noreferrer noopener"
                className="btn-article__link"
              >
                Leer más
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="article">
        <div className="article__shadow ">
          <img src={facturas} alt="Venta de Facturas" />
          <div className="article-info">
            <h2>Combate Frontal a l a Compra y Venta de Facturas</h2>
            <hr />
            <p>
              Recientemente se han dado a conocer propuestas de reformas
              fiscales tendientes a combatir el tráfico de facturas
            </p>
            <div className="btn-article">
              <NavLink
                exact
                to="/"
                target="_blank"
                rel="noreferrer noopener"
                className="btn-article__link"
              >
                Leer más
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="article">
        <div className="article__shadow ">
          <img src={gasolina} alt="Gasolina" />
          <div className="article-info">
            <h2>Impuestos a la gasolina, ¿hasta cuando su reducción?</h2>
            <hr />
            <p>
              Como es sabido dicho impuesto grava actos o actividades,
              destacándose la enajenación e importación de combustibles
              automotrices fósiles
            </p>
            <div className="btn-article">
              <NavLink
                exact
                to="/"
                target="_blank"
                rel="noreferrer noopener"
                className="btn-article__link"
              >
                Leer más
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Articles;
