import React from "react";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import { capacitacion } from "../utils/services";
import { ServicioCapacitacion } from "./ContentServices";

const Capacitacion = () => {
  const { intro, contenido, frase } = capacitacion;

  return (
    <>
      <Header banner="banner-capacitacion" title="capacitacion" />

      <ServicioCapacitacion intro={intro} contenido={contenido} frase={frase} />

      <Footer />
    </>
  );
};

export default Capacitacion;
