import React from "react";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import { defensa } from "../utils/services";
import { ServicioDefensa } from "./ContentServices";

const DefensaFiscal = () => {
  const { intro, contenido, frase } = defensa;

  return (
    <>
      <Header banner="banner-defensa" title="defensa fiscal creativa" />

      <ServicioDefensa intro={intro} contenido={contenido} frase={frase} />

      <Footer />
    </>
  );
};

export default DefensaFiscal;
