import React from "react";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import { corporativo } from "../utils/services";
import { ServicioPatrimonial } from "./ContentServices";

const CorporativoPatrimonial = () => {
  const { intro, contenido, frase } = corporativo;

  return (
    <>
      <Header banner="banner-corporativo" title="corporativo patrimonial" />

      <ServicioPatrimonial intro={intro} contenido={contenido} frase={frase} />

      <Footer />
    </>
  );
};

export default CorporativoPatrimonial;
