import React from "react";
import { NavLink } from "react-router-dom";

import logo from "../../assets/img/logotipo.jpg";

const Navigation = () => {
  return (
    <div className="navigation">
      <input
        type="checkbox"
        className="navigation__checkbox"
        id="navi-toggle"
      />

      <label htmlFor="navi-toggle" className="navigation__button">
        <span className="navigation__icon">&nbsp;</span>
      </label>

      <div className="navigation__background">&nbsp;</div>

      {/* <img src={logo} alt="Logo" className="navigation__img" /> */}

      <nav className="navigation__nav">
        <img src={logo} alt="Logo" />

        <ul className="navigation__list mt-small">
          <li className="navigation__item">
            <NavLink exact to="/" className="navigation__link">
              <span>01</span> Quiénes somos
            </NavLink>
          </li>

          <li className="navigation__item">
            <NavLink exact to="/servicios" className="navigation__link">
              <span>02</span> Servicios
            </NavLink>
          </li>

          <li className="navigation__item">
            <NavLink exact to="/publicaciones" className="navigation__link">
              <span>03</span> Publicaciones
            </NavLink>
          </li>

          <li className="navigation__item">
            <NavLink exact to="/articulos" className="navigation__link">
              <span>04</span> Articulos
            </NavLink>
          </li>

          <li className="navigation__item">
            <NavLink exact to="/contacto" className="navigation__link">
              <span>05</span> Contacto
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
