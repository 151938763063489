import React from "react";
import Footer from "../ui/Footer";
import Header from "../ui/Header";
import Form from "./Form";
import MapView from "./MapView";

const ContactScreen = () => {
  return (
    <>
      <Header
        banner="banner-contact"
        title="Contacto"
        quote="Nuestra experiencia a tu servicio"
      />
      
      <Form />
      <MapView />

      <Footer />
    </>
  );
};

export default ContactScreen;
