import React from "react";
import Footer from "../ui/Footer";
import Header from "../ui/Header";
import PublishBooks from "./PublishBooks";

const PublishScreen = () => {
  return (
    <>
      <Header
        banner="banner-publishes"
        title="Publicaciones"
        quote="El conocimiento nos hace responsables"
      />

      <PublishBooks />
      
      <Footer />
    </>
  );
};

export default PublishScreen;
