import React from "react";
import Footer from "../ui/Footer";
import Header from "../ui/Header";
import Articles from "./Articles";

const ArticlesScreen = () => {
  return (
    <>
      <Header
        banner="banner-articles"
        title="Artículos"
        quote="El mejor Don es la pasión por la lectura"
      />

      <Articles />

      <Footer />
    </>
  );
};

export default ArticlesScreen;
