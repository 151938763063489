import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import years from '../../assets/img/home/anios-experiencia.jpg';
import imgLibro from '../../assets/img/home/libros-jff.jpeg';
import imgCurso from '../../assets/img/home/curso-sellos-digitales.jpeg';

import lineRed from '../../assets/img/utilities/red-line.png';
import lineBlue from '../../assets/img/utilities/blue-line.png';

import promoCurso from '../../assets/docs/curso-sellos-digitales.pdf';
import promoLibro from "../../assets/docs/libros-2022B.pdf";

const HomeSections = () => {
    return (
        <main className="home mt-medium">
            <section className="home-whoami">
                <div className="home-whoami__content">
                    <img src={years} alt="Años de Experiencia" />
                    {/* delay={1000} */}
                    <ScrollAnimation
                        duration={2}
                        animateIn="animate__fadeInLeftBig"
                    >
                        <img
                            src={lineBlue}
                            alt="Línea"
                            className="home-line-blue"
                        />
                    </ScrollAnimation>

                    <p className="home-whoami__content__text mt-medium">
                        CorporativoASM Fiscalistas, lo integran un grupo
                        multidisciplinario de profesionales, cuyos miembros han
                        aportado un sin fin de cosas en el escenario tributario
                        nacional: revistas, libros, cursos, conferencias, así
                        como su incursión en radio y televisión. Todo
                        fortalecido con experiencia profesional, en los ámbitos
                        de la estrategia y lo corporativo, la defensa
                        tributaria, la defensa fiscal penal y, la protección
                        patrimonial; todo suscrito en el ámbito de la Gestión
                        Tributaria Empresarial.
                    </p>
                </div>

                <div className="home-whoami__img"></div>
            </section>

            <blockquote className="home-quote heading-phrase">
                <p>
                    “Porque una empresa correctamente gestionada origina mayor
                    innovación que cualquier individuo en solito”
                </p>
                <p>(Steve Jobs)</p>
            </blockquote>

            <section className="home-values">
                <div className="home-values__img"></div>

                <div className="home-values__text">
                    <div>
                        <h2 className="heading-3">Visión</h2>
                        <ScrollAnimation
                            duration={2}
                            animateIn="animate__fadeInLeftBig"
                            className="pd-sm"
                        >
                            <img
                                src={lineRed}
                                alt="Línea"
                                className="home-line-red"
                            />
                        </ScrollAnimation>
                        <p className="mb-big">
                            Coadyuvar en la Culturización Tributaria Empresarial
                            en México por medio de una Re-Evolución Tecnológica
                            y, ser el Corporativo líder en soluciones para el
                            empresario nacional e internacional.
                        </p>
                    </div>

                    <div>
                        <h2 className="heading-3">Misión</h2>
                        <ScrollAnimation
                            duration={2}
                            animateIn="animate__fadeInLeftBig"
                            className="pd-sm"
                        >
                            <img
                                src={lineRed}
                                alt="Línea"
                                className="home-line-red"
                            />
                        </ScrollAnimation>
                        <p>
                            Como firma de Fiscalistas, expertos en la Gestión
                            Empresarial, gracias al talento de un equipo
                            multidisciplinario, creamos, diseñamos e
                            implementamos la mejor tecnología fiscal, jurídica y
                            de negocios, al través de la prestación de Servicios
                            Premium, cuya característica radica en ser
                            apasionados con las causas, en aras de la protección
                            del patrimonio empresarial, utilizando para ello la
                            creatividad.
                        </p>
                    </div>
                </div>
            </section>

            <section className="home-promo mt-small">
                <div className="home-card">
                    <div className="home-card__side home-card__side--front">
                        <a
                            href={promoCurso}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={imgCurso} alt="Promoción Curso" />
                        </a>
                    </div>
                </div>

                <div className="home-card">
                    <div className="home-card__side home-card__side--front">
                        <a
                            href={promoLibro}
                            // href="https://corporativoasm-publicaciones.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={imgLibro} alt="Promoción Libro" />
                        </a>
                    </div>
                </div>

                {/* <div className="home-card mt-big">
          <div className="home-card__side">
            <iframe
              src="https://www.youtube.com/embed/SbHog-Cxiss"
              title="Entrevista"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div> */}
            </section>
        </main>
    );
};

export default HomeSections;
