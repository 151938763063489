import React from "react";

export const ServicioGestion = ({ intro, contenido, frase }) => {
  return (
    <>
      <section className="container-service">
        <div className="intro-service">
          <p>{intro}</p>
        </div>
      </section>

      <main className="content-service">
        <div className="service-color"></div>

        <div className="service-txt">
          {contenido.map((servicio, i) => (
            <div key={i}>
              <h3>{servicio.subtitulo}</h3>
              <hr className="service-line-blue" />
              <p>{servicio.parrafo}</p>

              {servicio.especial &&
                servicio.especial.map((list, i) => <p key={i}>{list}</p>)}
            </div>
          ))}
        </div>
      </main>

      <div className="heading-phrase hp-services">
        {frase}
      </div>
    </>
  );
};

export const ServicioPenal = ({ intro, contenido, frase }) => {
  return (
    <>
      <section className="container-service">
        <div className="intro-service">
          <p>{intro}</p>
        </div>
      </section>

      <main className="content-service">
        <div className="service-color"></div>

        <div className="service-txt">
          {contenido.map((servicio, i) => (
            <div key={i}>
              <h3>{servicio.subtitulo}</h3>
              <hr className="service-line-blue" />
              <p>{servicio.parrafo}</p>

              {servicio.especial &&
                servicio.especial.map((list, i) => <p key={i}>{list}</p>)}
            </div>
          ))}
        </div>
      </main>

      <div className="heading-phrase hp-services">
        {frase}
      </div>
    </>
  );
};

export const ServicioDefensa = ({ intro, contenido, frase }) => {
  return (
    <>
      <section className="container-service">
        <div className="intro-service">
          <p>{intro}</p>
        </div>
      </section>

      <main className="content-service">
        <div className="service-color"></div>

        <div className="service-txt">
          {contenido.map((servicio, i) => (
            <div key={i}>
              <h3>{servicio.subtitulo}</h3>
              <hr className="service-line-blue" />
              <p>{servicio.parrafo}</p>

              {servicio.especial &&
                servicio.especial.map((list, i) => <p key={i}>{list}</p>)}

              {servicio.parrafo2 && (
                <p className="mt-small">{servicio.parrafo2}</p>
              )}
            </div>
          ))}
        </div>
      </main>

      <div className="heading-phrase hp-services">
        {frase}
      </div>
    </>
  );
};

export const ServicioCapacitacion = ({ intro, contenido, frase }) => {
  return (
    <>
      <section className="container-service">
        <div className="intro-service">
          <p>{intro}</p>
        </div>
      </section>

      <main className="content-service">
        <div className="service-color"></div>

        <div className="service-txt">
          {contenido.map((servicio, i) => (
            <div key={i}>
              <h3>{servicio.subtitulo}</h3>
              <hr className="service-line-blue" />
              <p>{servicio.parrafo}</p>

              {servicio.especial &&
                servicio.especial.map((list, i) => <p key={i}>{list}</p>)}

              {servicio.parrafo2 && (
                <p className="mt-small">{servicio.parrafo2}</p>
              )}
            </div>
          ))}
        </div>
      </main>

      <div className="heading-phrase hp-services">
        {frase}
      </div>
    </>
  );
};

export const ServicioPatrimonial = ({ intro, contenido, frase }) => {
  return (
    <>
      <section className="container-service">
        <div className="intro-service">
          <p>{intro}</p>
        </div>
      </section>

      <main className="content-service">
        <div className="service-color"></div>

        <div className="service-txt">
          {contenido.map((servicio, i) => (
            <div key={i}>
              <h3>{servicio.subtitulo}</h3>
              <hr className="service-line-blue" />
              <p>{servicio.parrafo}</p>

              {servicio.parrafo2 && (
                <p className="mb-small">{servicio.parrafo2}</p>
              )}

              {servicio.especial &&
                servicio.especial.map((list, i) => <p key={i}>{list}</p>)}

              {servicio.parrafo3 && (
                <p className="mt-small">{servicio.parrafo3}</p>
              )}
            </div>
          ))}
        </div>
      </main>

      <div className="heading-phrase hp-services">
        {frase}
      </div>
    </>
  );
};
