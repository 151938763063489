export const gestion = {
  intro: `"La Gestión Tributaria consiste en aplicar aquellas alternativas
  permitidas por las leyes fiscales, que redunden en beneficios
  económicos y certeza jurídica en el contexto del patrimonio
  empresarial, que generen liquidez, al proporcionar elementos de cómo
  hacer mejor las cosas, requiriéndose para esto de una visión
  diferente en el ámbito de los negocios por parte de los empresarios,
  siendo imprescindible para ello contar con los siguiente servicios
  profesionales:"`,
  contenido: [
    {
      subtitulo: "Devoluciones y Compensaciones",
      parrafo:
        "La relación tributaria, también GENERAN DERECHOS que además significan fuentes de financiamientos como lo son la implementación de devoluciones y compensaciones ya sea por pago de lo indebido o saldos a favor en materia de IVA, ISR, Crédito al salario y Subsidio para el empleo, en beneficio de la liquidez de toda empresa.",
    },
    {
      subtitulo: "Aumentos y Reembolsos de Capital",
      parrafo:
        "La relación tributaria, también GENERAN DERECHOS que además significan fuentes de financiamientos como lo son la implementación de devoluciones y compensaciones ya sea por pago de lo indebido o saldos a favor en materia de IVA, ISR, Crédito al salario y Subsidio para el empleo, en beneficio de la liquidez de toda empresa.",
    },
    {
      subtitulo: "Reestructuración de Empresas",
      parrafo:
        "EL COSTO FISCAL VA DE LA MANO CON LAS FIGURAS JURIDICAS inmersas en los negocios, siendo indispensable analizar si la estructura    jurídica es la correcta o en su caso replantearlas.",
    },
    {
      subtitulo: "Distribución de Dividendos",
      parrafo:
        "Generalmente AL ACCIONISTA O SOCIO SE LE REMUMERA POR SALARIOS, ASIMILADOS, etc., olvidando la figura de dividendos, siendo las ventajas entre otras, acorde con cada caso:",
      especial: [
        "a) El no pago de ISR por los dividendos recibidos, salvo retención del 10%, de ISR.",
        "b) El no pago por parte de la empresa que distribuye los dividendos.",
        "c) Le genera al socio o accionista transparencia en el origen de sus dineros.",
      ],
    },
  ],
  frase: `“EL TRABAJO ORDINARIO EN EQUIPO PRODUCE COSAS EXTRAORDINARIAS…”`,
};

export const penal = {
  intro: `En el escenario fiscal nacional y acorde con nuestro sistema tributario, EXISTEN DOS CLASES DE INFRACCIONES que son sancionadas por el Código Fiscal de la Federación: `,
  contenido: [
    {
      especial: [
        "1. Por el no pago de contribuciones y el no cumplimiento de obligaciones formales, traduciéndose en sanciones administrativas o económicas.",
        "2. Por la omisión en el pago de contribuciones de manera dolosa, cuya sanción se traduce en la privación de la libertad de los individuos.",
      ],
    },
    {
      parrafo:
        "Siendo la segunda la que más daña y afecta el patrimonio empresarial, derivándose las siguiente interrogantes:",
      especial: [
        "• ¿Todos los delitos fiscales merecen privación de la libertad?",
        "• ¿Existen factores que indican si una conducta conlleva alguna consecuencia de tipo penal fiscal?",
        "• ¿Qué elementos se requieren para que una acción u omisión sea tipificada como delito fiscal?",
        "• ¿En qué consiste el perdón fiscal?",
        "• ¿La privación de la libertad conlleva el no pago del crédito fiscal adeudado al fisco?",
        "• ¿Qué implicación tiene que un delito fiscal sea grave o no?",
      ],
    },
    {
      parrafo:
        "En fin, algunas interrogantes, los cuales se pueden subsanar con una defensa fiscal penal adecuada, direccionada con un equipo de abogados penalistas fiscalistas, altamente calificado en el ámbito penal, con los siguiente servicios profesionales: ",
      especial: [
        "• Interposición de amparos contra orden de aprehensión",
        "• Seguimiento al proceso penal fiscal",
        "• Amparo o apelación",
      ],
    },
  ],
  frase: `“CULTURA DE LA PREVENCIÓN vs CULTURA DE LA CORRECCIÓN” `,
};

export const defensa = {
  intro: `Conscientes de la necesidad que tienen nuestros clientes de contar con asesoría legal y corporativa, no podrían estar sólidamente respaldadas sin la defensa de sus derechos. Por lo que, practicamos de manera proactiva e innovadora los medios impugnativos que ofrecen las leyes de la materia, buscando las mejores Alternativas de defensa para una resolución favorable.`,
  contenido: [
    {
      subtitulo: "Consultas",
      parrafo:
        "Para OBTENER CERTEZA JURÍDICA, en caso de duda se promueve ante la autoridad fiscal una consulta de manera concreta cuestiones específicas sobre deducciones, ingresos y devoluciones, entra otras, teniendo ella la obligación de contestar.",
    },
    {
      subtitulo: "Recurso de Revocación",
      parrafo:
        "Promoción y atención de recursos administrativos ante las propias autoridades fiscales, siendo importante valorar el caso en particular antes de ir a una siguiente instancia.",
    },
    {
      subtitulo: "Recursos de Inconformidad",
      parrafo:
        "Se implementan ante VIOLACIONES DE LEGALIDAD por parte del Instituto Mexicano del Seguro y del INFONAVIT.",
    },
    {
      subtitulo: "Juicio Contencioso Administrativo",
      parrafo:
        "Cuando un acto de la autoridad fiscal es ilegal (créditos fiscales improcedentes, negación de devoluciones, etc.), se recurre al través de Juicio Contencioso Administrativo PARA DEMANDAR LA NULIDAD de la misma.",
      especial: [
        "• Juicio en la vía tradicional",
        "• Juicio en línea",
        "• Juicio sumario",
        "• Juicio de resolución exclusiva de fondo",
      ],
      parrafo2:
        "En donde aplicamos técnicas de prevención; manejo eficiente de las controversias; manejo de alternativas de defensa acorde a las leyes mexicanas y los tratados internacionales; detección de áreas de riesgo y elaboración de expedientes de defensa estratégicos que permitan identificar y reducir los riesgos.",
    },
    {
      subtitulo: "Juicio de Garantías Tributarias",
      parrafo:
        "Nuestra constitución establece una serie de disposiciones que protege los derechos humanos frente a la actuación de la autoridades, Pudiendo exigir estos derechos al través del Amparo directo e indirecto, para hacer que se RESPETE LA LEGALIDAD Y/O CONSTITUCIONALIDAD DE UNA SENTENCIA dictada por un tribunal ordinario, así como CONTRA LEYES QUE VIOLENTEN GARANTÍAS CONSTITUCIONALES del ámbito tributario.",
    },
  ],
  frase: `“PARA JUSTICIA FISCAL ALCANZAR, ES PRECISO SABER SOLICITAR…” `,
};

export const capacitacion = {
  intro: `A lo largo de los años y producto de la experiencia como instructores en diversos foros del país, degustando y compartiendo con empresarios, contadores, abogados, administradores, entre otros, el CorporativoASM Fiscalistas da un valor agregado, por medio de la transmisión de conocimientos, cuyo quehacer se desarrolla en nuestras instalaciones, tendiendo un puente que fortalezca el crecimiento y progreso de las empresas.`,
  contenido: [
    {
      parrafo:
        "Todo esto genera capital intelectual, lo que a su vez explícitamente crea riqueza, con la finalidad de tener elementos objetivos para minimizar los riesgos inherentes a la toma de decisión en el mundo de los negocios.",
    },
    {
      subtitulo: "Entre los cursos que ofrecemos se encuentran:",
      especial: [
        "1. Estrategias financieras de los impuestos",
        "2. Outsourcing",
        "3. Defensa fiscal creativa y sus alternativas",
        "4. Gestión tributaria empresarial",
        "5. Discrepancia fiscal de los empresarios",
        "6. Reformas fiscales",
      ],
      parrafo2:
        "Nuestros expositores son reconocidos a nivel nacional, que además de la teoría tienen como valor agregado la aplicación de sus conocimientos en la práctica profesional empresarial, cuyos saberes son de aplicación inmediata, siendo imperioso desafiar los limites del razonamiento, con la finalidad de generar ideas que coadyuven al quehacer empresarial. ",
    },
  ],
  frase: `“PORQUE UN HOMBRE CAPACITADO GENERA RIQUEZA…”`,
};

export const corporativo = {
  intro: `Una correcta PLATAFORMA LEGAL genera CERTEZA PATRIMONIAL ADEMÁS DE LOS BENEFICIOS FISCALES implícitos, siendo importante analizar que figuras jurídicas posee el contribuyente o ente económico.`,
  contenido: [
    {
      subtitulo: "Constitución de Sociedades",
      parrafo:
        "UNA BUENA PLANEACIÓN empieza desde la CONFIGURACIÓN y CREACIÓN DE LA PERSONA MORAL:",
      especial: [
        "- Con fines especulativos",
        "- Con fines económicos",
        "- Con fines no lucrativos",
      ],
    },
    {
      subtitulo: "Formulación de Actas de Asamblea",
      parrafo:
        "La finalidad de las asambleas es MANIFESTAR LAS VOLUNTAD DE LOS SOCIOS O ACCIONISTAS ya sea para el reparto de utilidades, autorización de aportaciones o reembolsos de capital o bien de ciertas erogaciones, etc.; teniendo todo ello beneficios o repercusiones fiscales de importancia, además de proporcionar certeza patrimonial.",
    },
    {
      subtitulo: "Modificaciones Estatutarias",
      parrafo:
        "LAS DEDUCCIONES AUTORIZADAS ESTÁN CONDICIONADAS AL OBJETO SOCIAL, los cuales pueden ser direccionadas.",
    },
    {
      subtitulo: "Liquidación de Sociedades",
      parrafo:
        "Cuando una EMPRESAS se encuentra sin operaciones, es necesario EXTINGUIRLAS protegiendo con esto el ámbito jurídico y fiscal, con la finalidad de evitar riesgos en el futuro.",
    },
    {
      subtitulo: "Registro de Marcas y Patentes",
      parrafo:
        "Trámites ante el IMPI, lo que le generará la obtención de la propiedad del trabajo intelectual y creativo, EN PRO DE LA GENERACIÓN INTANGIBLES con ventaja impositiva.",
    },
    {
      subtitulo: "Alianzas Estratégicas por Medio de Contratos",
      parrafo:
        "En el mundo de los negocios en todo momento los empresarios buscan formas o maneras de realizar más transacciones, siendo necesario unir fuerzas con otros empresarios con la finalidad de hacer más contundente su quehacer, lo que deriva en una serie de interrogantes del cómo ejecutarlos, con el máximo de beneficios y el mínimo de riesgos, siendo importante la búsqueda de los caminos más idóneos.",
      parrafo2:
        "Entre otras herramientas de Gestión con la visión de hilar alianzas estratégicas, se prevén las siguientes figuras:",
      especial: [
        "• Promesa",
        "• Compra-venta",
        "• Mandato",
        "• Mutuo",
        "• Servicios profesionales (asimilados)",
        "• De fondo evolvente",
        "• De depósito",
        "• Sustitución de deudor",
        "• Comodato",
        "• De franquicia",
        "• Asociación en participación",
        "• Copropiedad mercantil",
      ],
      parrafo3:
        "Previo estudio para cada caso específico, de su régimen de tributación y sus implicaciones, en beneficio de las decisiones empresariales.",
    },
  ],
  frase: `“PORQUE UNA ESTRUCTURA JURÍDICA EFICIENTE, GENERA BENEFICIOS FISCALES Y PATRIMONIALES…” `,
};
