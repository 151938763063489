import React from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
// SON LOS ESTILOS PARA QUE SE VEA EL MAPA
import "leaflet/dist/leaflet.css";

// import { ReactComponent as LocationIcon } from "../../assets/icons/location_icon.svg";

const MapView = () => {
  const iconLocation = L.icon({
    iconUrl: require("../../assets/icons/location_icon.svg"),
    iconRetinaUrl: require("../../assets/icons/location_icon.svg"),
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [65, 65],
    className: "Leaflet-venue-icon",
  });

  return (
    // Map--> ENCAPSULA TODA LA LÓGICA DEL MAPA
    // DENTRO DE MAP:
    // center={lat:"", lng:""}--> COORDENADAS
    // zoom--> PARA DECIDIR QUE TAN CERCA O LEJOS QUEREMOS VER POR DEAFAULT EL MAPA
    <Map
      center={{ lat: "19.3502336", lng: "-99.1511185" }}
      zoom={15}
      className="mt-small"
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      <Marker
        position={{ lat: "19.3502336", lng: "-99.1511185" }}
        icon={iconLocation}
      >
        <Popup className="popup">
          <p className="popup__title">CorporativoASM Fiscalistas</p>

          <p className="popup__content">
            Av.División del Norte No.2723 Desp.402-B
            <br />
            Col.Barrio San Lucas Del. Coyoacán,
            <br />
            Entre las calles Priv. Irlanda e Hidalgo
            <br />
            C.P. 04030 México, Ciudad de México
            <br />
          </p>
        </Popup>
      </Marker>
    </Map>
  );
};

export default MapView;
