import React from "react";
// import { NavLink } from "react-router-dom";

import efi from "../../assets/img/publishes/libro-efi.png";
import juicioFiscal from "../../assets/img/publishes/libro-jff.png";
import pilares from "../../assets/img/publishes/libro-pte.png";
import codigoFiscal from "../../assets/img/publishes/codigo-fiscal.jpg";
import iva from "../../assets/img/publishes/iva.jpg";
import isrPm from "../../assets/img/publishes/isr-pm.jpg";

const PublishBooks = () => {
  return (
    <main className="books">
      <section className="intro">
        <div className="intro-books">
          <p>
            A lo largo de los años nuestro Director General ARNULFO SÁNCHEZ
            MIRANDA, se ha dado a la tarea de desarrollar un conjunto de obras
            con la firme intención de dar a conocer el por qué, para qué y del
            cómo de los tributos en México, pero también de su defensa.
          </p>
          <p>
            El primer fruto salió al mercado en 1997, “Aplicación práctica del
            Código Fiscal” que en la actualidad tiene en su haber 20 ediciones;
            “Manual práctico del pequeño contribuyente” (1998), colocándose en
            el mercado nacional cerca de 10,000 ejemplares en su primera edición
            y posteriormente dar cabida a “Resolución Miscelánea. Casos
            prácticos Integrales” (1999).
          </p>
        </div>
      </section>

      <section className="books-section">
        <div className="book book-asm">
          <div className="book__side book__side--front">
            <img src={efi} alt="Libro" />
            <div className="book-info">
              <h3>Estrategias Financieras de los Impuestos</h3>
              <div className="btns">
                <a
                  href="https://corporativoasm-publicaciones.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Comprar
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="book book-asm">
          <div className="book__side book__side--front">
            <img src={juicioFiscal} alt="Libro" />
            <div className="book-info">
              <h3>Juicio Fiscal de Fondo</h3>
              <div className="btns">
                <a
                  href="https://corporativoasm-publicaciones.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Comprar
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="book book-asm">
          <div className="book__side book__side--front">
            <img src={pilares} alt="Libro" />
            <div className="book-info">
              <h3>Juicio Fiscal de Fondo</h3>
              <div className="btns">
                <a
                  href="https://corporativoasm-publicaciones.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Comprar
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="book">
          <div className="book__side book__side--front">
            <img src={codigoFiscal} alt="Libro" />
            <div className="book-info">
              <h3>Código Fiscal de la Federación</h3>
              <div className="btns">
                {/* <NavLink
                  exact
                  to="/corporativo-patrimonial"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Contenido
                </NavLink>
                <NavLink
                  exact
                  to="/corporativo-patrimonial"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Comprar
                </NavLink> */}
              </div>
            </div>
          </div>
        </div>

        <div className="book">
          <div className="book__side book__side--front">
            <img src={iva} alt="Libro" />
            <div className="book-info">
              <h3>Impuesto al Valor Agregado</h3>
              <div className="btns">
                {/* <NavLink
                  exact
                  to="/corporativo-patrimonial"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Contenido
                </NavLink>
                <NavLink
                  exact
                  to="/corporativo-patrimonial"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Comprar
                </NavLink> */}
              </div>
            </div>
          </div>
        </div>

        <div className="book">
          <div className="book__side book__side--front">
            <img src={isrPm} alt="Libro" />
            <div className="book-info">
              <h3>ISR Personas Morales</h3>
              <div className="btns">
                {/* <NavLink
                  exact
                  to="/corporativo-patrimonial"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Contenido
                </NavLink>
                <NavLink
                  exact
                  to="/corporativo-patrimonial"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Comprar
                </NavLink> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PublishBooks;
