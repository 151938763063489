import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import Navbar from "./Navbar";
import Navigation from "./Navigation";

import btnPromo from "../../assets/img/utilities/btn-red.png";
import line from "../../assets/img/utilities/red-line.png";
// import promoLibro from "../../assets/docs/promo-noviembre-2020.pdf";
import infoCurso from '../../assets/docs/curso-sellos-digitales.pdf';

const Header = ({ banner, title, quote }) => {
  const [pos, setPos] = useState(false);

  useEffect(() => {
    window.onload = () => {
      let windowWidth = window.innerWidth;
      if (windowWidth < 1024) {
        setPos(true);
      } else {
        setPos(false);
      }
    };

    window.addEventListener("resize", () => {
      let windowWidth = window.innerWidth;
      if (windowWidth < 1024) {
        setPos(true);
      } else {
        setPos(false);
      }
    });
  }, []);

  return (
    <header className={banner}>
      {pos ? <Navigation /> : <Navbar />}

      <div className="header-info">
        <ScrollAnimation duration={1.8} animateIn="animate__fadeInDownBig">
          <h1 className="heading">{title}</h1>
        </ScrollAnimation>
        {/* SE MUESTRA UNA LINEA ROJA O BLANCA */}
        {/* {banner === ("banner-home" || "banner-services") ? (
          import line from "../../assets/img/utilities/red-line.png";
          <img src={line} alt="Linea" className="header-line" />
        ) : (
          <hr className="header-line-white" />
        )} */}

        <ScrollAnimation duration={2} animateIn="animate__fadeInLeftBig">
          <img src={line} alt="Linea" className="header-line" />
        </ScrollAnimation>

        <ScrollAnimation duration={1.8} animateIn="animate__fadeInUpBig">
          <p className="heading-2">{quote}</p>
        </ScrollAnimation>
      </div>

      {banner === 'banner-home' && (
        <div className="promo-course">
          <div className="promo-course__btn-promo">
            <a href={infoCurso} target="_blank" rel="noreferrer noopener">
              <img src={btnPromo} alt="Boton" />
            </a>
            {/* <a href={promoLibro} target="_blank" rel="noreferrer noopener">
              <img src={btnPromo} alt="Boton" />
            </a> */}
          </div>

          <div className="promo-course__info">
            <h3>¡nuevo curso-taller!</h3>
            <p>SELLOS DIGITALES</p>

            {/* LEYENDAS DE ABAJO */}
            <div className="webinar mt-small">
              <div className="webinar__izq">curso-taller</div>
              <div className="webinar__der">online</div>
            </div>
            {/* <div className="webinar mt-small">
              <div className="webinar__izq">hasta:</div>
              <div className="webinar__der">30-noviembre-2020</div>
            </div> */}
          </div>
        </div>
      )}

      {/* <!-- Facebook Pixel Code --> */}

      {/* <!-- End Facebook Pixel Code --> */}
    </header>
  );
};

export default Header;
