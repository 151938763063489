import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import ArticlesScreen from "../components/articles/ArticlesScreen";
import ContactScreen from "../components/contact/ContactScreen";
import HomeScreen from "../components/home/HomeScreen";
import PublishScreen from "../components/publish/PublishScreen";

import ServicesScreen from "../components/services/ServicesScreen";
import Capacitacion from "../components/services/Capacitacion";
import CorporativoPatrimonial from "../components/services/CorporativoPatrimonial";
import DefensaFiscal from "../components/services/DefensaFiscal";
import GestionTributaria from "../components/services/GestionTributaria";
import PenalFiscal from "../components/services/PenalFiscal";

import ScrollToTop from "../components/ui/ScrollToTop";
export const AppRouter = () => {
  return (
    <Router> 
      <ScrollToTop />     
      <Switch>
        <Route exact path="/servicios" component={ServicesScreen} />
        <Route exact path="/gestion-tributaria" component={GestionTributaria} />
        <Route exact path="/penal-fiscal-estrategico" component={PenalFiscal} />
        <Route exact path="/defensa-fiscal-creativa" component={DefensaFiscal} />
        <Route exact path="/capacitacion" component={Capacitacion} />
        <Route exact path="/corporativo-patrimonial" component={CorporativoPatrimonial} />
        <Route exact path="/publicaciones" component={PublishScreen} />
        <Route exact path="/articulos" component={ArticlesScreen} />
        <Route exact path="/contacto" component={ContactScreen} />
        <Route exact path="/" component={HomeScreen} />

        <Redirect to="/" />
      </Switch>
    </Router>
  );
};
