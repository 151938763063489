import React from "react";
import { AppRouter } from "./routes/AppRouter";
import "animate.css/animate.min.css";

const AsmApp = () => {
  return (
    <AppRouter />
  );
};

export default AsmApp;
